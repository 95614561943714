// import { center } from '@turf/turf';
// import jsonObj from 'assets/dashboard/**/*.json'; // Get all the assets paths in the build or API (in future)

const trackDefinitions = [
  {
    id: '11',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 4,
    // county: 'Vestland',
    trackId: '11-1',
    slug: 'bergensbanen',
    name: 'Bergensbanen',
    title: 'Bergensbanen',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [6.42, 60.7],
    zoom: 7, // Disantce:
    // assets: jsonObj?.['bratsbergbanen'],
    deviceMac: 'None',
    apiTrackId: 'bergensbanen',
    trackName: 'bergensbanen',
    trackIdentifier: 'bergensbanen',
    hasCatenary: true
  },
  {
    id: '6',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 1,
    // county: 'Vestfold og Telemark',
    trackId: '6-1',
    slug: 'bratsbergbanen',
    name: 'Bratsbergbanen',
    title: 'Bratsbergbanen',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [9.3899235, 59.2074275],
    zoom: 9.5, // Disantce: 18.22
    // assets: jsonObj?.['bratsbergbanen'],
    deviceMac: 'None',
    apiTrackId: 'bratsbergbanen',
    trackName: 'bratsbergbanen',
    trackIdentifier: 'bratsbergbanen',
    hasCatenary: true
  },
  {
    id: '1',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 1,
    // county: 'Vestfold og Telemark',
    trackId: '1-1',
    slug: 'brevikbanen',
    name: 'Brevikbanen',
    title: 'Brevikbanen',
    // center: center(jsonObj['brevikbanen']?.geojson?.track)?.geometry
    //   ?.coordinates,
    center: [9.659503, 59.06],
    zoom: 11.8, // Distance 4.43
    // assets: jsonObj?.brevikbanen,
    deviceMac: 'A4:CF:12:77:1A:F8',
    apiTrackId: 'brevikbanen',
    trackName: 'brevikbanen',
    trackIdentifier: 'brevikbanen_ib',
    hasRailCant: true,
    hasCatenary: true,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '2',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 2,
    // county: 'Vestland',
    trackId: '2-1',
    slug: 'bybanen_1',
    name: 'Bybanen 1',
    title: 'Bybanen 1 outbound',
    // center: center(jsonObj['bybanen_1']?.geojson?.track)?.geometry?.coordinates,
    center: [5.2463755, 60.339768],
    zoom: 11, // Distance: 6.79
    // assets: jsonObj?.['bybanen_1'],
    deviceMac: 'A4:CF:12:77:07:00',
    apiTrackId: 'bybanen_1',
    gaugeApiTrackId: 'bybanen_1',
    trackName: 'bybanen_1',
    trackIdentifier: 'bybanen_1',
    hasRailCant: true,
    hasRailCantSurface: true,
    // elevationApiTrackId: 'bybanen_ob',
    // elevationApiDirection: 'ob',
    hasCatenary: true,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '3',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 2,
    // county: 'Vestland',
    trackId: '2-2',
    slug: 'bybanen_2',
    name: 'Bybanen 2',
    title: 'Bybanen 2 inbound',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [5.2465045, 60.3395115],
    zoom: 11, // Distance 6.82
    // assets: jsonObj?.['bybanen_2'],
    deviceMac: 'A4:CF:12:77:07:00',
    apiTrackId: 'bybanen_2',
    gaugeApiTrackId: 'bybanen_2',
    trackName: 'bybanen_2',
    trackIdentifier: 'bybanen_2',
    hasRailCant: true,
    hasRailCantSurface: true,
    // elevationApiTrackId: 'bybanen_ib',
    // elevationApiDirection: 'ib',
    hasCatenary: true,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '8',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 4,
    // county: 'Viken',
    trackId: '8-1',
    slug: 'dovrebanen',
    name: 'Dovrebanen',
    title: 'Dovrebanen',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [9.7, 61.2],
    zoom: 7.4, // Disantce:
    // assets: jsonObj?.['bratsbergbanen'],
    deviceMac: 'None',
    apiTrackId: 'dovrebanen',
    trackName: 'dovrebanen',
    trackIdentifier: 'dovrebanen',
    hasCatenary: true
  },
  {
    id: '9',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 4,
    // county: 'Viken',
    trackId: '9-1',
    slug: 'drammenbanen',
    name: 'Drammenbanen',
    title: 'Drammenbanen',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [10.31, 59.8],
    zoom: 10.3, // Disantce:
    // assets: jsonObj?.['bratsbergbanen'],
    deviceMac: 'None',
    apiTrackId: 'drammenbanen',
    trackName: 'drammenbanen',
    trackIdentifier: 'drammenbanen',
    // hasRailCant: true,
    // elevationApiTrackId: 'drammenbanen_ib',
    hasCatenary: true
  },
  {
    id: '7',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 4,
    // county: 'Viken',
    trackId: '7-1',
    slug: 'gardermobanen_1',
    name: 'Gardermobanen 1',
    title: 'Gardermobanen 1 Outbound',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [10.8, 60],
    zoom: 9, // Disantce: 18.22
    // assets: jsonObj?.['bratsbergbanen'],
    deviceMac: 'None',
    apiTrackId: 'gardermobanen',
    apiTrackGroups: ['gardermobanen'],
    apiTrackDirection: 'ob',
    trackName: 'gardermobanen_ob',
    trackIdentifier: 'gardermobanen_ob',
    hasRailCant: true,
    elevationApiTrackId: 'gardermobanen_ob',
    // elevationApiDirection: 'ob',
    railCantSections: [
      {
        name: 'Oslo S - Lillestrøm',
        identifier: 'gardermobanen_os_li_ob',
        apiTrackId: 'no_gardermobanen_oslos_ob',
        // trackStart: 5571,
        // trackStop: 17811,
        center: [10.961337373547735, 59.94556678562185],
        zoom: 13.188188431406553
      },
      {
        name: 'Lillestrøm - Oslo Lufthavn',
        identifier: 'gardermobanen_li_lu_ob',
        apiTrackId: 'no_gardermobanen_lillestrom_ob',
        // trackStart: 17811,
        // trackStop: 50091,
        center: [11.04697599336032, 59.97329676004247],
        zoom: 13.188188431406553
      }
    ],
    hasCatenary: true,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '16',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 4,
    // county: 'Viken',
    trackId: '16-2',
    slug: 'gardermobanen_2',
    name: 'Gardermobanen 2',
    title: 'Gardermobanen 2 Inbound',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [10.8, 60],
    zoom: 9, // Disantce: 18.22
    // assets: jsonObj?.['bratsbergbanen'],
    deviceMac: 'None',
    apiTrackId: 'gardermobanen',
    apiTrackGroups: ['gardermobanen'],
    apiTrackDirection: 'ib',
    trackName: 'gardermobanen_ib',
    trackIdentifier: 'gardermobanen_ib',
    hasRailCant: true,
    elevationApiTrackId: 'no_gardermobanen_ib',
    // elevationApiDirection: 'ib',
    railCantSections: [
      {
        name: 'Oslo Lufthavn - Lillestrøm',
        identifier: 'gardermobanen_lu_li_ib',
        apiTrackId: 'no_gardermobanen_lillestrom_ib',
        // trackStart: 17811,
        // trackStop: 50091,
        center: [11.04697599336032, 59.97329676004247],
        zoom: 13.188188431406553
      },
      {
        name: 'Lillestrøm - Oslo S',
        identifier: 'gardermobanen_li_os_ib',
        apiTrackId: 'no_gardermobanen_oslos_ib',
        // trackStart: 5571,
        // trackStop: 17811,
        center: [10.961337373547735, 59.94556678562185],
        zoom: 13.188188431406553
      }
    ],
    hasCatenary: true,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '15',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '15-1',
    slug: 'nordlandsbanen',
    name: 'Nordlandsbanen',
    title: 'Nordlandsbanen',
    center: [13.218, 65.7842],
    zoom: 7,
    deviceMac: 'None',
    apiTrackId: 'nordlandsbanen',
    trackName: 'nordlandsbanen',
    trackIdentifier: 'nordlandsbanen',
    hasCatenary: true
  },
  {
    id: '14',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 5,
    // county: '-',
    trackId: '14-1',
    slug: 'randsfjordbanen',
    name: 'Randsfjordbanen',
    title: 'Randsfjordbanen',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [9.7, 59.9],
    zoom: 9, // Disantce:
    // assets: jsonObj?.['bratsbergbanen'],
    deviceMac: 'None',
    apiTrackId: 'randsfjordbanen',
    trackName: 'randsfjordbanen',
    trackIdentifier: 'randsfjordbanen',
    hasCatenary: true
  },
  {
    id: '26',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '26-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    slug: 'no_oslo_subway_berg_ob',
    name: 'Sognsvann (T-Bane)',
    title: 'Sognsvann (T-Bane) Outbound',
    center: [10.743220241782238, 59.9593075333155],
    zoom: 9,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_berg_ob',
    apiTrackGroups: ['no_oslo_subway_sognsvann'],
    apiTrackDirection: 'ob',
    trackName: 'no_oslo_subway_berg_ob',
    trackIdentifier: 'no_oslo_subway_berg_ob',
    hasRailCant: true,
    elevationApiTrackId: 'no_oslo_subway_berg_ob',
    railCantSections: [
      {
        name: 'Berg - Sognsvann',
        identifier: 'no_oslo_subway_berg_ob',
        apiTrackId: 'no_oslo_subway_berg_ob',
        center: [10.740220241782238, 59.9573075333155],
        zoom: 14.6
      }
    ],
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '27',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '27-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    slug: 'no_oslo_subway_sognsvann_ib',
    name: 'Sognsvann (T-Bane)',
    title: 'Sognsvann (T-Bane) Inbound',
    center: [10.743220241782238, 59.9593075333155],
    zoom: 9,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_sognsvann_ib',
    apiTrackGroups: ['no_oslo_subway_sognsvann'],
    apiTrackDirection: 'ib',
    trackName: 'no_oslo_subway_sognsvann_ib',
    trackIdentifier: 'no_oslo_subway_sognsvann_ib',
    hasRailCant: true,
    elevationApiTrackId: 'no_oslo_subway_sognsvann_ib',
    railCantSections: [
      {
        name: 'Sognsvann - Berg',
        identifier: 'no_oslo_subway_sognsvann_ib',
        apiTrackId: 'no_oslo_subway_sognsvann_ib',
        center: [10.740220241782238, 59.9573075333155],
        zoom: 14.6
      }
    ],
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '28',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '28-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    isVisionEnabled: process.env.REACT_FEATURE_VISION === 'true',
    slug: 'no_oslo_subway_kolsas_ob',
    name: 'Kolsås (T-Bane)',
    title: 'Kolsås (T-Bane) Outbound',
    center: [10.50957532142013, 59.93986624546463],
    zoom: 11.688561574841843,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_kolsas_ob',
    apiTrackGroups: ['no_oslo_subway_kolsas'],
    apiTrackDirection: 'ob',
    trackName: 'no_oslo_subway_kolsas_ob',
    trackIdentifier: 'no_oslo_subway_kolsas_ob',
    hasRailCant: true,
    elevationApiTrackId: 'no_oslo_subway_kolsas_ob',
    railCantSections: [
      {
        name: 'Makrellbekken - Kols\u00e5s',
        identifier: 'no_oslo_subway_makrellbekken_ob',
        apiTrackId: 'no_oslo_subway_makrellbekken_ob',
        center: [10.50957532142013, 59.93986624546463],
        zoom: 11.688561574841843
      }
    ],
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '29',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '29-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    isVisionEnabled: process.env.REACT_FEATURE_VISION === 'true',
    slug: 'no_oslo_subway_kolsas_ib',
    name: 'Kolsås (T-Bane)',
    title: 'Kolsås (T-Bane) Inbound',
    center: [10.50957532142013, 59.93986624546463],
    zoom: 11.688561574841843,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_kolsas_ib',
    apiTrackGroups: ['no_oslo_subway_kolsas'],
    apiTrackDirection: 'ib',
    trackName: 'no_oslo_subway_kolsas_ib',
    trackIdentifier: 'no_oslo_subway_kolsas_ib',
    hasRailCant: true,
    elevationApiTrackId: 'no_oslo_subway_kolsas_ib',
    railCantSections: [
      {
        name: 'Kolsås - Makrellbekken',
        identifier: 'no_oslo_subway_kolsas_ib',
        apiTrackId: 'no_oslo_subway_kolsas_ib',
        center: [10.50957532142013, 59.93986624546463],
        zoom: 11.688561574841843
      }
    ],
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '30',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '30-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    slug: 'no_oslo_subway_roa_ob',
    name: 'Røabanen (T-Bane)',
    title: 'Røabanen (T-Bane) Outbound',
    center: [10.660564220044892, 59.94166940520947],
    zoom: 14.6,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_roa_ob',
    apiTrackGroups: ['no_oslo_subway_roa'],
    apiTrackDirection: 'ob',
    trackName: 'no_oslo_subway_roa_ob',
    trackIdentifier: 'no_oslo_subway_roa_ob',
    hasRailCant: true,
    elevationApiTrackId: 'no_oslo_subway_roa_ob',
    railCantSections: [
      {
        name: 'Makrellbekken - Østerås',
        identifier: 'no_oslo_subway_makrellbekken_osteras_ob',
        apiTrackId: 'no_oslo_subway_makrellbekken_osteras_ob',
        center: [10.614782780612813, 59.94522789654417],
        zoom: 13.060374298975265
      },
      {
        name: 'Majorstuen - Makrellbekken',
        identifier: 'no_oslo_subway_majorstuen_makrellbekken_ob',
        apiTrackId: 'no_oslo_subway_majorstuen_makrellbekken_ob',
        center: [10.677983358503297, 59.93905549287507],
        zoom: 13.728413337218644
      }
    ],
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '31',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '31-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    slug: 'no_oslo_subway_roa_ib',
    name: 'Røabanen (T-Bane)',
    title: 'Røabanen (T-Bane) Inbound',
    center: [10.659806110132578, 59.942006073666505],
    zoom: 14.6,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_roa_ib',
    apiTrackGroups: ['no_oslo_subway_roa'],
    apiTrackDirection: 'ib',
    trackName: 'no_oslo_subway_roa_ib',
    trackIdentifier: 'no_oslo_subway_roa_ib',
    hasRailCant: true,
    elevationApiTrackId: 'no_oslo_subway_roa_ib',
    railCantSections: [
      {
        name: 'Østerås - Makrellbekken',
        identifier: 'no_oslo_subway_osteras_makrellbekken_ib',
        apiTrackId: 'no_oslo_subway_osteras_makrellbekken_ib',
        center: [10.614782780612813, 59.94522789654417],
        zoom: 13.060374298975265
      },
      {
        name: 'Makrellbekken - Majorstuen',
        identifier: 'no_oslo_subway_makrellbekken_majorstuen_ib',
        apiTrackId: 'no_oslo_subway_makrellbekken_majorstuen_ib',
        center: [10.677983358503297, 59.93905549287507],
        zoom: 13.728413337218644
      }
    ],
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '32',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '32-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    slug: 'no_oslo_subway_furuset_ob',
    name: 'Furusetbanen (T-Bane)',
    title: 'Furusetbanen (T-Bane) Outbound',
    center: [10.833049698964487, 59.93025967937888],
    zoom: 12.690620132206911,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_furuset_ob',
    apiTrackGroups: ['no_oslo_subway_furuset'],
    apiTrackDirection: 'ob',
    trackName: 'no_oslo_subway_furuset_ob',
    trackIdentifier: 'no_oslo_subway_furuset_ob',
    hasRailCant: true,
    elevationApiTrackId: 'no_oslo_subway_furuset_ob',
    railCantSections: [
      {
        name: 'Hellerud - Furuset',
        identifier: 'no_oslo_subway_furuset_ob',
        apiTrackId: 'no_oslo_subway_furuset_ob',
        center: [10.833049698964487, 59.93025967937888],
        zoom: 12.690620132206911
      }
    ],
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '33',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '33-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    slug: 'no_oslo_subway_furuset_ib',
    name: 'Furusetbanen (T-Bane)',
    title: 'Furusetbanen (T-Bane) Inbound',
    center: [10.833049698964487, 59.93025967937888],
    zoom: 12.690620132206911,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_furuset_ib',
    apiTrackGroups: ['no_oslo_subway_furuset'],
    apiTrackDirection: 'ib',
    trackName: 'no_oslo_subway_furuset_ib',
    trackIdentifier: 'no_oslo_subway_furuset_ib',
    hasRailCant: true,
    elevationApiTrackId: 'no_oslo_subway_furuset_ib',
    railCantSections: [
      {
        name: 'Furuset - Hellerud',
        identifier: 'no_oslo_subway_furuset_ib',
        apiTrackId: 'no_oslo_subway_furuset_ib',
        center: [10.833049698964487, 59.93025967937888],
        zoom: 12.690620132206911
      }
    ],
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '34',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '34-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    slug: 'no_oslo_subway_centrum_ob',
    name: 'Centrumbanen (T-Bane)',
    title: 'Centrumbanen (T-Bane) Outbound',
    center: [10.714546133317526, 59.93932116302338],
    zoom: 12.688561574841843,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_centrum_ob',
    apiTrackGroups: ['no_oslo_subway_centrum'],
    apiTrackDirection: 'ob',
    trackName: 'no_oslo_subway_centrum_ob',
    trackIdentifier: 'no_oslo_subway_centrum_ob',
    hasRailCant: false,
    elevationApiTrackId: 'no_oslo_subway_centrum_ob',
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '35',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '35-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    slug: 'no_oslo_subway_centrum_ib',
    name: 'Centrumbanen (T-Bane)',
    title: 'Centrumbanen (T-Bane) Inbound',
    center: [10.714546133317526, 59.93932116302338],
    zoom: 12.688561574841843,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_centrum_ib',
    apiTrackGroups: ['no_oslo_subway_centrum'],
    apiTrackDirection: 'ib',
    trackName: 'no_oslo_subway_centrum_ib',
    trackIdentifier: 'no_oslo_subway_centrum_ib',
    hasRailCant: false,
    elevationApiTrackId: 'no_oslo_subway_centrum_ib',
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '36',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '36-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    slug: 'no_oslo_subway_grorud_ob',
    name: 'Grorudbanen (T-Bane)',
    title: 'Grorudbanen (T-Bane) Outbound',
    center: [10.797347225767908, 59.95253656474705],
    zoom: 12.688561574841843,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_grorud_ob',
    apiTrackGroups: ['no_oslo_subway_grorud'],
    apiTrackDirection: 'ob',
    trackName: 'no_oslo_subway_grorud_ob',
    trackIdentifier: 'no_oslo_subway_grorud_ob',
    hasRailCant: false,
    elevationApiTrackId: 'no_oslo_subway_grorud_ob',
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '37',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '37-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    slug: 'no_oslo_subway_grorud_ib',
    name: 'Grorudbanen (T-Bane)',
    title: 'Grorudbanen (T-Bane) Inbound',
    center: [10.797347225767908, 59.95253656474705],
    zoom: 12.688561574841843,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_grorud_ib',
    apiTrackGroups: ['no_oslo_subway_grorud'],
    apiTrackDirection: 'ib',
    trackName: 'no_oslo_subway_grorud_ib',
    trackIdentifier: 'no_oslo_subway_grorud_ib',
    hasRailCant: false,
    elevationApiTrackId: 'no_oslo_subway_grorud_ib',
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '38',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '38-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    isVisionEnabled: process.env.REACT_FEATURE_VISION === 'true',
    slug: 'no_oslo_subway_holmenkollen_ob',
    name: 'Holmenkollbanen (T-Bane)',
    title: 'Holmenkollbanen (T-Bane) Outbound',
    center: [10.66212282711183, 59.95478088228589],
    zoom: 12.688561574841845,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_holmenkollen_ob',
    apiTrackGroups: ['no_oslo_subway_holmenkollen'],
    apiTrackDirection: 'ob',
    trackName: 'no_oslo_subway_holmenkollen_ob',
    trackIdentifier: 'no_oslo_subway_holmenkollen_ob',
    hasRailCant: false,
    elevationApiTrackId: 'no_oslo_subway_holmenkollen_ob',
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '39',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '39-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    slug: 'no_oslo_subway_holmenkollen_ib',
    name: 'Holmenkollbanen (T-Bane)',
    title: 'Holmenkollbanen (T-Bane) Inbound',
    center: [10.66212282711183, 59.95478088228589],
    zoom: 12.688561574841845,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_holmenkollen_ib',
    apiTrackGroups: ['no_oslo_subway_holmenkollen'],
    apiTrackDirection: 'ib',
    trackName: 'no_oslo_subway_holmenkollen_ib',
    trackIdentifier: 'no_oslo_subway_holmenkollen_ib',
    hasRailCant: false,
    elevationApiTrackId: 'no_oslo_subway_holmenkollen_ib',
    hasCatenary: false,
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '5',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 3,
    // county: 'Agder',
    trackId: '5-1',
    slug: 'soerlandsbanen',
    name: 'Sørlandsbanen',
    title: 'Sørlandsbanen',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [6.4117145, 58.958898000000005],
    zoom: 7, // Distance: 155.67
    // assets: jsonObj?.['soerlandsbanen'],
    deviceMac: 'None',
    apiTrackId: 'soerlandsbanen',
    trackName: 'soerlandsbanen',
    trackIdentifier: 'soerlandsbanen',
    hasCatenary: true
  },
  {
    id: '4',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 1,
    // county: 'Vestfold og Telemark',
    trackId: '4-1',
    slug: 'vestfoldbanen',
    name: 'Vestfoldbanen',
    title: 'Vestfoldbanen',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [9.75, 59.2],
    zoom: 8.7, //Distance: 40.13
    // assets: jsonObj?.['vestfoldbanen'],
    deviceMac: 'None',
    apiTrackId: 'vestfoldbanen',
    trackName: 'vestfoldbanen',
    trackIdentifier: 'vestfoldbanen',
    hasCatenary: true
  },
  {
    id: '10',
    countryId: 'norway',
    countryCode: 'no',
    // countyId: 4,
    // county: 'Viken',
    trackId: '10-1',
    slug: 'oestfoldbanen_vestre',
    name: 'Østfoldbanen vestre',
    title: 'Østfoldbanen vestre',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [10.9, 59.2],
    zoom: 9, // Disantce:
    // assets: jsonObj?.['bratsbergbanen'],
    deviceMac: 'None',
    apiTrackId: 'oestfoldbanen_vestre',
    trackName: 'oestfoldbanen_vestre',
    trackIdentifier: 'oestfoldbanen_vestre',
    hasCatenary: true
  },
  {
    id: '12',
    countryId: 'sweden',
    countryCode: 'se',
    // countyId: 5,
    // county: '-',
    trackId: '12-1',
    slug: 'norge_vanerbanan',
    name: 'Norge/Vänerbanan',
    title: 'Norge/Vänerbanan',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [11.8, 58.1],
    zoom: 9, // Disantce:
    // assets: jsonObj?.['bratsbergbanen'],
    deviceMac: 'None',
    apiTrackId: 'norge_vanerbanan',
    trackName: 'norge_vanerbanan',
    trackIdentifier: 'norge_vanerbanan'
  },
  {
    id: '13',
    countryId: 'sweden',
    countryCode: 'se',
    // countyId: 5,
    // county: '-',
    trackId: '13-1',
    slug: 'vastkustbanan',
    name: 'Västkustbanan',
    title: 'Västkustbanan',
    // center: center(jsonObj['bybanen_2']?.geojson?.track)?.geometry?.coordinates,
    center: [12.2, 56.2],
    zoom: 8.3, // Disantce:
    // assets: jsonObj?.['bratsbergbanen'],
    deviceMac: 'None',
    apiTrackId: 'vastkustbanan',
    trackName: 'vastkustbanan',
    trackIdentifier: 'vastkustbanan'
  },
  //  New Zealand tracks
  {
    id: '17',
    countryId: 'new_zealand',
    countryCode: 'nz',
    trackId: '17-1',
    slug: 'manukau-branch-left',
    name: 'Manukau Branch Left',
    title: 'Manukau Branch Left',
    center: [174.86091489082128, -36.98938540475908],
    zoom: 14.919322828654453,
    deviceMac: 'None',
    apiTrackId: 'nz_wiri_manukau_l',
    apiTrackGroups: ['nz_manukau'],
    apiTrackDirection: 'ob',
    trackName: 'nz_wiri_manukau_l',
    trackIdentifier: 'nz_wiri_manukau_l',
    hasRailCant: true,
    elevationApiTrackId: 'nz_manuk_wiri_ob',
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '18',
    countryId: 'new_zealand',
    countryCode: 'nz',
    trackId: '18-1',
    slug: 'manukau-branch-right',
    name: 'Manukau Branch Right',
    title: 'Manukau Branch Right',
    center: [174.86091489082128, -36.98938540475908],
    zoom: 14.919322828654453,
    deviceMac: 'None',
    apiTrackId: 'nz_wiri_manukau_r',
    apiTrackGroups: ['nz_manukau'],
    apiTrackDirection: 'ib',
    trackName: 'nz_wiri_manukau_r',
    trackIdentifier: 'nz_wiri_manukau_r',
    hasRailCant: true,
    elevationApiTrackId: 'nz_manuk_manukau_ib',
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '20',
    countryId: 'new_zealand',
    countryCode: 'nz',
    trackId: '20-1',
    slug: 'newmarket-left',
    name: 'Newmarket Left',
    title: 'Newmarket Left',
    center: [174.77686935511417, -36.85859985818222],
    zoom: 15.072939417398961,
    deviceMac: 'None',
    apiTrackId: 'nz_britomart_newmarket_l',
    apiTrackGroups: ['nz_nwmkt'],
    apiTrackDirection: 'ob',
    trackName: 'nz_britomart_newmarket_l',
    trackIdentifier: 'nz_britomart_newmarket_l',
    hasRailCant: true,
    elevationApiTrackId: 'nz_nwmkt_newmarket_ob',
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '19',
    countryId: 'new_zealand',
    countryCode: 'nz',
    trackId: '19-1',
    slug: 'newmarket-right',
    name: 'Newmarket Right',
    title: 'Newmarket Right',
    center: [174.77686935511417, -36.85859985818222],
    zoom: 15.072939417398961,
    deviceMac: 'None',
    apiTrackId: 'nz_britomart_newmarket_r',
    apiTrackGroups: ['nz_nwmkt'],
    apiTrackDirection: 'ib',
    trackName: 'nz_britomart_newmarket_r',
    trackIdentifier: 'nz_britomart_newmarket_r',
    hasRailCant: true,
    elevationApiTrackId: 'nz_nwmkt_britomart_ib',
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '21',
    countryId: 'new_zealand',
    countryCode: 'nz',
    trackId: '21-1',
    slug: 'onehunga-branch',
    name: 'Onehunga Branch',
    title: 'Onehunga Branch',
    center: [174.78962004485038, -36.915960125276385],
    zoom: 14.587361866897835,
    deviceMac: 'None',
    apiTrackId: 'nz_penrose_onehunga',
    apiTrackGroups: ['nz_onehunga'],
    apiTrackDirection: 'ob',
    trackName: 'nz_penrose_onehunga',
    trackIdentifier: 'nz_penrose_onehunga',
    hasRailCant: true,
    elevationApiTrackId: 'nz_onehunga_penrose_ob',
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '23',
    countryId: 'new_zealand',
    countryCode: 'nz',
    trackId: '23-1',
    slug: 'nal-left-westfield-swanson',
    name: 'NAL Left Westfield Swanson',
    title: 'NAL Left Westfield Swanson',
    center: [174.60528064539199, -36.850671907300125],
    zoom: 11.228699883667064,
    deviceMac: 'None',
    apiTrackId: 'nz_westfield_swanson_l',
    apiTrackGroups: ['nz_nal1', 'nz_nal2'],
    apiTrackDirection: 'ob',
    trackName: 'nz_westfield_swanson_l',
    trackIdentifier: 'nz_westfield_swanson_l',
    hasRailCant: true,
    railCantSections: [
      // NAL - Part 1
      {
        name: 'Westfield - Penrose',
        identifier: 'nz_nal_westfield_ob',
        apiTrackId: 'nz_nal_westfield_ob',
        center: [174.8160810744289, -36.921030442448746],
        zoom: 14.6
      },
      {
        name: 'Penrose - Newmarket',
        identifier: 'nz_nal_penrose_ob',
        apiTrackId: 'nz_nal_penrose_ob',
        center: [174.79489999999998, -36.89005],
        zoom: 14.6
      },
      // NAL - Part 2
      {
        name: 'Newmarket - Grafton',
        identifier: 'nz_nal_newmarket_ob',
        apiTrackId: 'nz_nal_newmarket_ob',
        center: [174.7697105, -36.8660325],
        zoom: 14.6,
        // This section has known issues
        lowQualityTrackModel: true
      },
      {
        name: 'Morningside - Mount Albert',
        identifier: 'nz_nal_morningside_ob',
        apiTrackId: 'nz_nal_morningside_ob',
        center: [174.722230505, -36.88036968],
        zoom: 14.6
      },
      {
        name: 'Mount Albert - Avondale',
        identifier: 'nz_nal_mountalbert_ob',
        apiTrackId: 'nz_nal_mountalbert_ob',
        center: [174.70499999999998, -36.892858000000004],
        zoom: 14.6
      },
      {
        name: 'Avondale - New Lynn',
        identifier: 'nz_nal_avondale_ob',
        apiTrackId: 'nz_nal_avondale_ob',
        center: [174.69642913500002, -36.903405864999996],
        zoom: 14.6
      },
      {
        name: 'New Lynn - Fruitvale Road',
        identifier: 'nz_nal_newlynn_ob',
        apiTrackId: 'nz_nal_newlynn_ob',
        center: [174.67749928, -36.909724465],
        zoom: 14.6
      },
      {
        name: 'Fruitvale Road - Sunnyvale',
        identifier: 'nz_nal_fruitvale_ob',
        apiTrackId: 'nz_nal_fruitvale_ob',
        center: [174.648408085, -36.90646447],
        zoom: 14.6
      },
      {
        name: 'Sunnyvale - Swanson',
        identifier: 'nz_nal_sunnyvale_ob',
        apiTrackId: 'nz_nal_sunnyvale_ob',
        center: [174.60265671500002, -36.881669395],
        zoom: 14.6
      }
    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '22',
    countryId: 'new_zealand',
    countryCode: 'nz',
    trackId: '22-1',
    slug: 'nal-right-westfield-swanson',
    name: 'NAL Right Westfield Swanson',
    title: 'NAL Right Westfield Swanson',
    center: [174.60528064539199, -36.850671907300125],
    zoom: 11.228699883667064,
    deviceMac: 'None',
    apiTrackId: 'nz_westfield_swanson_r',
    apiTrackGroups: ['nz_nal1', 'nz_nal2'],
    apiTrackDirection: 'ib',
    trackName: 'nz_westfield_swanson_r',
    trackIdentifier: 'nz_westfield_swanson_r',
    hasRailCant: true,
    railCantSections: [
      // NAL - Part 2
      {
        name: 'Swanson - Sunnyvale',
        identifier: 'nz_nal_swanson_ib',
        apiTrackId: 'nz_nal_swanson_ib',
        center: [174.60265671500002, -36.881669395],
        zoom: 14.6
      },
      {
        name: 'Sunnyvale - Fruitvale Road',
        identifier: 'nz_nal_sunnyvale_ib',
        apiTrackId: 'nz_nal_sunnyvale_ib',
        center: [174.648408085, -36.90646447],
        zoom: 14.6
      },
      {
        name: 'Fruitvale Road - New Lynn',
        identifier: 'nz_nal_fruitvale_ib',
        apiTrackId: 'nz_nal_fruitvale_ib',
        center: [174.67749928, -36.909724465],
        zoom: 14.6
      },
      {
        name: 'New Lynn - Avondale',
        identifier: 'nz_nal_newlynn_ib',
        apiTrackId: 'nz_nal_newlynn_ib',
        center: [174.69642913500002, -36.903405864999996],
        zoom: 14.6
      },
      {
        name: 'Avondale - Mount Albert',
        identifier: 'nz_nal_avondale_ib',
        apiTrackId: 'nz_nal_avondale_ib',
        center: [174.70499999999998, -36.892858000000004],
        zoom: 14.6
      },
      {
        name: 'Mount Albert - Morningside',
        identifier: 'nz_nal_mountalbert_ib',
        apiTrackId: 'nz_nal_mountalbert_ib',
        center: [174.722230505, -36.88036968],
        zoom: 14.6
      },
      {
        name: 'Morningside - Kingsland',
        identifier: 'nz_nal_morningside_ib',
        apiTrackId: 'nz_nal_morningside_ib',
        center: [174.73982576999998, -36.873964395],
        zoom: 14.6
      },
      {
        name: 'Grafton - Newmarket',
        identifier: 'nz_nal_grafton_ib',
        apiTrackId: 'nz_nal_grafton_ib',
        center: [174.7697105, -36.8660325],
        zoom: 14.6,
        // This section has known issues
        lowQualityTrackModel: true
      },
      // NAL - Part 1
      {
        name: 'Newmarket - Penrose',
        identifier: 'nz_nal_newmarket_ib',
        apiTrackId: 'nz_nal_newmarket_ib',
        center: [174.79489999999998, -36.89005],
        zoom: 14.6
      },
      {
        name: 'Penrose - Westfield',
        identifier: 'nz_nal_penrose_ib',
        apiTrackId: 'nz_nal_penrose_ib',
        center: [174.8160810744289, -36.921030442448746],
        zoom: 14.6
      }
    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '24',
    countryId: 'new_zealand',
    countryCode: 'nz',
    trackId: '24-1',
    slug: 'nimt-left-britomart-hamilton',
    name: 'NIMT Left Britomart Hamilton',
    title: 'NIMT Left Britomart Hamilton',
    center: [174.88421744255163, -37.44494099655333],
    zoom: 10.083722596517607,
    deviceMac: 'None',
    apiTrackId: 'nz_hamilton_britomart_l',
    apiTrackGroups: ['nz_nimt'],
    apiTrackDirection: 'ob',
    trackName: 'nz_hamilton_britomart_l',
    trackIdentifier: 'nz_hamilton_britomart_l',
    hasRailCant: true,
    railCantSections: [
      // Disabled for now as there is no data available
      // {
      //   name: "Westield - Britomart",
      //   identifier: "nz_nimt_westfield_ob",
      //   apiTrackId: "nz_nimt_westfield_ob",
      //   center: [174.81298399999997, -36.8877225],
      //   zoom: 14.6,
      // },
      {
        name: 'Homai - Westfield',
        identifier: 'nz_nimt_homai_ob',
        apiTrackId: 'nz_nimt_homai_ob',
        center: [174.850055, -36.972319999999996],
        // center: [174.85077024571126, -36.99427082050886],
        zoom: 14.225284719850356
      },
      {
        name: 'Papakura - Homai',
        identifier: 'nz_nimt_papakura_ob',
        apiTrackId: 'nz_nimt_papakura_ob',
        center: [174.90926000000002, -37.037615],
        zoom: 14.6
      },
      {
        name: 'Drury - Papakura',
        identifier: 'nz_nimt_drury_ob',
        apiTrackId: 'nz_nimt_drury_ob',
        center: [174.95, -37.084],
        // center: [174.93744547276947, -37.092709407276104],
        zoom: 14.661963088207335
      },
      {
        name: 'Pukekohe - Drury',
        identifier: 'nz_nimt_pukekohe_ob',
        apiTrackId: 'nz_nimt_pukekohe_ob',
        center: [174.92325, -37.13965],
        // center: [174.89404350193854, -37.16067398933138],
        zoom: 14.056314335934724
      },
      {
        name: 'Tuakau - Pukekohe',
        identifier: 'nz_nimt_tuakau_ob',
        apiTrackId: 'nz_nimt_tuakau_ob',
        center: [174.90943523901683, -37.21455348122106],
        zoom: 14.6
      },
      {
        name: 'Pokeno - Tuakau',
        identifier: 'nz_nimt_pokeno_ob',
        apiTrackId: 'nz_nimt_pokeno_ob',
        center: [174.96206712557978, -37.24749671551729],
        zoom: 13.761490222712887
      },
      {
        name: 'Amokura - Mercer',
        identifier: 'nz_nimt_amokura_ob',
        apiTrackId: 'nz_nimt_amokura_ob',
        center: [175.05430192991184, -37.29158459882747],
        zoom: 14.26598048087831
      },
      {
        name: 'Tekauwhata - Amokura',
        identifier: 'nz_nimt_tekauwhata_ob',
        apiTrackId: 'nz_nimt_tekauwhata_ob',
        center: [175.10395759893504, -37.365458864734784],
        zoom: 14.26598048087831
      },
      {
        name: 'Huntlynorth - Tekauwhata',
        identifier: 'nz_nimt_huntlynorth_ob',
        apiTrackId: 'nz_nimt_huntlynorth_ob',
        center: [175.14916849271685, -37.470768426665046],
        zoom: 14.6
      },
      {
        name: 'Huntly - Huntlynorth',
        identifier: 'nz_nimt_huntly_ob',
        apiTrackId: 'nz_nimt_huntly_ob',
        center: [175.1535010253267, -37.54284389427048],
        zoom: 14.6
      },
      {
        name: 'Taupiri - Huntly',
        identifier: 'nz_nimt_taupiri_ob',
        apiTrackId: 'nz_nimt_taupiri_ob',
        center: [175.16563230203496, -37.59806196968898],
        zoom: 14.26598048087831
      },
      {
        name: 'Ngaruwahia - Taupiri',
        identifier: 'nz_nimt_ngaruawahia_ob',
        apiTrackId: 'nz_nimt_ngaruawahia_ob',
        center: [175.15176650140415, -37.66041135329396],
        zoom: 14.26598048087831
      },
      {
        name: 'Hamilton - Ngaruawahia',
        identifier: 'nz_nimt_hamilton_ob',
        apiTrackId: 'nz_nimt_hamilton_ob',
        center: [175.20179371831318, -37.727372347377276],
        zoom: 14.02669820869367
      }
    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '25',
    countryId: 'new_zealand',
    countryCode: 'nz',
    trackId: '25-1',
    slug: 'nimt-right-britomart-hamilton',
    name: 'NIMT Right Britomart Hamilton',
    title: 'NIMT Right Britomart Hamilton',
    center: [174.88421744255163, -37.44494099655333],
    zoom: 10.083722596517607,
    deviceMac: 'None',
    apiTrackId: 'nz_hamilton_britomart_r',
    apiTrackGroups: ['nz_nimt'],
    apiTrackDirection: 'ib',
    trackName: 'nz_hamilton_britomart_r',
    trackIdentifier: 'nz_hamilton_britomart_r',
    hasRailCant: true,
    railCantSections: [
      // Disabled for now as there is no data available
      // {
      //     name: "Britomart - Westfield",
      //     identifier: "nz_nimt_britomart_ib",
      //     apiTrackId: "nz_nimt_britomart_ib",
      //     center: [
      //         174.81298399999997,
      //         -36.8877225
      //     ],
      //     zoom: 14.6,
      // },
      {
        name: 'Westfield - Homai',
        identifier: 'nz_nimt_westfield_ib',
        apiTrackId: 'nz_nimt_westfield_ib',
        center: [174.850055, -36.972319999999996],
        zoom: 14.6
      },
      {
        name: 'Homai - Papakura',
        identifier: 'nz_nimt_homai_ib',
        apiTrackId: 'nz_nimt_homai_ib',
        center: [174.90926000000002, -37.037615],
        zoom: 14.6
      },
      {
        name: 'Papakura - Drury',
        identifier: 'nz_nimt_papakura_ib',
        apiTrackId: 'nz_nimt_papakura_ib',
        center: [174.95, -37.084],
        zoom: 14.6
      },
      {
        name: 'Drury - Pukekohe',
        identifier: 'nz_nimt_drury_ib',
        apiTrackId: 'nz_nimt_drury_ib',
        center: [174.92325, -37.13965],
        zoom: 14.6
      },
      {
        name: 'Pukekohe - Tuakau',
        identifier: 'nz_nimt_pukekohe_ib',
        apiTrackId: 'nz_nimt_pukekohe_ib',
        center: [174.90943523901683, -37.21141],
        zoom: 14.6
      },
      {
        name: 'Tuakau - Pokeno',
        identifier: 'nz_nimt_tuakau_ib',
        apiTrackId: 'nz_nimt_tuakau_ib',
        center: [174.96206712557978, -37.24749671551729],
        zoom: 13.761490222712887
      },
      {
        name: 'Mercer - Amokura',
        identifier: 'nz_nimt_mercer_ib',
        apiTrackId: 'nz_nimt_mercer_ib',
        center: [175.05430192991184, -37.29158459882747],
        zoom: 14.26598048087831
      },
      {
        name: 'Amokura - Tekauwhata',
        identifier: 'nz_nimt_amokura_ib',
        apiTrackId: 'nz_nimt_amokura_ib',
        center: [175.10395759893504, -37.365458864734784],
        zoom: 14.26598048087831
      },
      {
        name: 'Tekauwhata - Huntlynorth',
        identifier: 'nz_nimt_tekauwhata_ib',
        apiTrackId: 'nz_nimt_tekauwhata_ib',
        center: [175.14916849271685, -37.470768426665046],
        zoom: 14.6
      },
      {
        name: 'Huntlynorth - Huntly',
        identifier: 'nz_nimt_huntlynorth_ib',
        apiTrackId: 'nz_nimt_huntlynorth_ib',
        center: [175.1535010253267, -37.54284389427048],
        zoom: 14.6
      },
      {
        name: 'Huntly - Taupiri',
        identifier: 'nz_nimt_huntly_ib',
        apiTrackId: 'nz_nimt_huntly_ib',
        center: [175.16563230203496, -37.59806196968898],
        zoom: 14.26598048087831
      },
      {
        name: 'Taupiri - Ngaruawahia',
        identifier: 'nz_nimt_taupiri_ib',
        apiTrackId: 'nz_nimt_taupiri_ib',
        center: [175.15176650140415, -37.66041135329396],
        zoom: 14.26598048087831
      },
      {
        name: 'Ngaruawahia - Hamilton',
        identifier: 'nz_nimt_ngaruawahia_ib',
        apiTrackId: 'nz_nimt_ngaruawahia_ib',
        center: [175.20179371831318, -37.727372347377276],
        zoom: 14.02669820869367
      }
    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '40',
    countryId: 'new_zealand',
    countryCode: 'nz',
    trackId: '40-1',
    slug: 'wairarapa-left',
    name: 'Wairarapa Left',
    title: 'Wairarapa Left',
    center: [174.81399372446958, -41.11293712126941],
    zoom: 9.596077379015798,
    deviceMac: 'None',
    apiTrackId: 'nz_wairarapa_l',
    apiTrackGroups: ['nz_wairarapa'],
    apiTrackDirection: 'ob',
    trackName: 'nz_wairarapa_l',
    trackIdentifier: 'nz_wairarapa_l',
    hasRailCant: true,
    railCantSections: [
      {
        'name': 'Wellington - Horokiwi',
        'identifier': 'nz_wairarapa_wellington1_horokiwi_ob',
        'apiTrackId': 'nz_wairarapa_wellington1_horokiwi_ob',
        'center': [
          174.8147345,
          -41.24591298105916
        ],
        'zoom': 14.6
      },
      {
        'name': 'Horokiwi - Petone',
        'identifier': 'nz_wairarapa_horokiwi_petone_ob',
        'apiTrackId': 'nz_wairarapa_horokiwi_petone_ob',
        'center': [
          174.86343175,
          -41.2229710936172
        ],
        'zoom': 14.6
      },
      {
        'name': 'Petone - Woburn',
        'identifier': 'nz_wairarapa_petone_woburn_ob',
        'apiTrackId': 'nz_wairarapa_petone_woburn_ob',
        'center': [
          174.8971284,
          -41.220534313382345
        ],
        'zoom': 14.6
      },
      {
        'name': 'Woburn - Naenae',
        'identifier': 'nz_wairarapa_woburn_naenae_ob',
        'apiTrackId': 'nz_wairarapa_woburn_naenae_ob',
        'center': [
          174.9308246946515,
          -41.207881835496494
        ],
        'zoom': 14.6
      },
      {
        'name': 'Naenae - Pomare',
        'identifier': 'nz_wairarapa_naenae_pomare_ob',
        'apiTrackId': 'nz_wairarapa_naenae_pomare_ob',
        'center': [
          174.9608793,
          -41.18083498591604
        ],
        'zoom': 14.6
      },
      {
        'name': 'Pomare - Heretaunga',
        'identifier': 'nz_wairarapa_pomare_heretaunga_ob',
        'apiTrackId': 'nz_wairarapa_pomare_heretaunga_ob',
        'center': [
          174.99604554999996,
          -41.15662183544113
        ],
        'zoom': 14.6
      },
      {
        'name': 'Heretaunga - Featherston',
        'identifier': 'nz_wairarapa_heretaunga_featherston_ob',
        'apiTrackId': 'nz_wairarapa_heretaunga_featherston_ob',
        'center': [
          175.1685195,
          -41.12540232433854
        ],
        'zoom': 14.6
      },
      {
        'name': 'Featherston - Woodside',
        'identifier': 'nz_wairarapa_featherston_woodside_ob',
        'apiTrackId': 'nz_wairarapa_featherston_woodside_ob',
        'center': [
          175.36312699999996,
          -41.09245746772084
        ],
        'zoom': 14.6
      },
      {
        'name': 'Woodside - Masterton',
        'identifier': 'nz_wairarapa_woodside_masterton_ob',
        'apiTrackId': 'nz_wairarapa_woodside_masterton_ob',
        'center': [
          175.53353675,
          -41.001585386323725
        ],
        'zoom': 14.6
      }
    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '41',
    countryId: 'new_zealand',
    countryCode: 'nz',
    trackId: '41-1',
    slug: 'wairarapa-right',
    name: 'Wairarapa Right',
    title: 'Wairarapa Right',
    center: [174.81399372446958, -41.11293712126941],
    zoom: 9.596077379015798,
    deviceMac: 'None',
    apiTrackId: 'nz_wairarapa_r',
    apiTrackGroups: ['nz_wairarapa'],
    apiTrackDirection: 'ib',
    trackName: 'nz_wairarapa_r',
    trackIdentifier: 'nz_wairarapa_r',
    hasRailCant: true,
    railCantSections: [
      {
        'name': 'Masterton - Woodside',
        'identifier': 'nz_wairarapa_masterton_woodside_ib',
        'apiTrackId': 'nz_wairarapa_masterton_woodside_ib',
        'center': [
          175.53353675,
          -41.001585443635506
        ],
        'zoom': 13.19
      },
      {
        'name': 'Woodside - Featherston',
        'identifier': 'nz_wairarapa_woodside_featherston_ib',
        'apiTrackId': 'nz_wairarapa_woodside_featherston_ib',
        'center': [
          175.36312699999996,
          -41.092457496775225
        ],
        'zoom': 14.6
      },
      {
        'name': 'Featherston - Heretaunga',
        'identifier': 'nz_wairarapa_featherston_heretaunga_ib',
        'apiTrackId': 'nz_wairarapa_featherston_heretaunga_ib',
        'center': [
          175.1685195,
          -41.12540232433854
        ],
        'zoom': 14.6
      },
      {
        'name': 'Heretaunga - Pomare',
        'identifier': 'nz_wairarapa_heretaunga_pomare_ib',
        'apiTrackId': 'nz_wairarapa_heretaunga_pomare_ib',
        'center': [
          174.99604554999996,
          -41.15662183544113
        ],
        'zoom': 14.6
      },
      {
        'name': 'Pomare - Naenae',
        'identifier': 'nz_wairarapa_pomare_naenae_ib',
        'apiTrackId': 'nz_wairarapa_pomare_naenae_ib',
        'center': [
          174.9608793,
          -41.18083498591604
        ],
        'zoom': 14.6
      },
      {
        'name': 'Naenae - Woburn',
        'identifier': 'nz_wairarapa_naenae_woburn_ib',
        'apiTrackId': 'nz_wairarapa_naenae_woburn_ib',
        'center': [
          174.93076505,
          -41.207881835496494
        ],
        'zoom': 14.6
      },
      {
        'name': 'Woburn - Petone',
        'identifier': 'nz_wairarapa_woburn_petone_ib',
        'apiTrackId': 'nz_wairarapa_woburn_petone_ib',
        'center': [
          174.89715915,
          -41.220850792059785
        ],
        'zoom': 14.6
      },
      {
        'name': 'Petone - Horokiwi',
        'identifier': 'nz_wairarapa_petone_horokiwi_ib',
        'apiTrackId': 'nz_wairarapa_petone_horokiwi_ib',
        'center': [
          174.86343175,
          -41.22326119391689
        ],
        'zoom': 14.6
      },
      {
        'name': 'Horokiwi - Wellington',
        'identifier': 'nz_wairarapa_horokiwi_wellington1_ib',
        'apiTrackId': 'nz_wairarapa_horokiwi_wellington1_ib',
        'center': [
          174.8147345,
          -41.24591298105916
        ],
        'zoom': 14.6
      }
    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '42',
    countryId: 'united_kingdom',
    countryCode: 'gb',
    trackId: '42-1',
    slug: 'shoeburyness-inbound',
    name: 'Shoeburyness Inbound',
    title: 'Shoeburyness Inbound',
    center: [0.18661887502400987, 51.58335244536033],
    zoom: 10.247275920708509,
    deviceMac: 'None',
    apiTrackId: 'tr_gb_shoeburyness_ib',
    apiTrackGroups: ['tg_gb_shoeburyness'],
    apiTrackDirection: 'ib',
    trackName: 'tr_gb_shoeburyness_ib',
    trackIdentifier: 'tr_gb_shoeburyness_ib',
    hasRailCant: true,
    railCantSections: [
      {
        'name': 'Shoeburyness - Benfleet',
        'identifier': 'ts_gb_shoeburyness_ib_1',
        'apiTrackId': 'ts_gb_shoeburyness_ib_1',
        'center': [
          0.6776612,
          51.53796329106907
        ],
        'zoom': 13
      },
      {
        'name': 'Benfleet - Laindon',
        'identifier': 'ts_gb_shoeburyness_ib_2',
        'apiTrackId': 'ts_gb_shoeburyness_ib_2',
        'center': [
          0.45025105,
          51.55701122875883
        ],
        'zoom': 13
      },
      {
        'name': 'Laindon - Barking',
        'identifier': 'ts_gb_shoeburyness_ib_3',
        'apiTrackId': 'ts_gb_shoeburyness_ib_3',
        'center': [
          0.2101755,
          51.553431577149524
        ],
        'zoom': 13
      },
      {
        'name': 'Barking - London',
        'identifier': 'ts_gb_shoeburyness_ib_4',
        'apiTrackId': 'ts_gb_shoeburyness_ib_4',
        'center': [
          0.0032622,
          51.52888847697138
        ],
        'zoom': 13
      }
    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '43',
    countryId: 'united_kingdom',
    countryCode: 'gb',
    trackId: '43-1',
    slug: 'shoeburyness-outbound',
    name: 'Shoeburyness Outbound',
    title: 'Shoeburyness Outbound',
    center: [0.18661887502400987, 51.58335244536033],
    zoom: 10.247275920708509,
    deviceMac: 'None',
    apiTrackId: 'tr_gb_shoeburyness_ob',
    apiTrackGroups: ['tg_gb_shoeburyness'],
    apiTrackDirection: 'ob',
    trackName: 'tr_gb_shoeburyness_ob',
    trackIdentifier: 'tr_gb_shoeburyness_ob',
    hasRailCant: true,
    railCantSections: [
      {
        'name': 'London - Barking',
        'identifier': 'ts_gb_shoeburyness_ob_1',
        'apiTrackId': 'ts_gb_shoeburyness_ob_1',
        'center': [
          0.0032622,
          51.52888847697138
        ],
        'zoom': 13
      },
      {
        'name': 'Barking - Laindon',
        'identifier': 'ts_gb_shoeburyness_ob_2',
        'apiTrackId': 'ts_gb_shoeburyness_ob_2',
        'center': [
          0.2101755,
          51.553431577149524
        ],
        'zoom': 13
      },
      {
        'name': 'Laindon - Benfleet',
        'identifier': 'ts_gb_shoeburyness_ob_3',
        'apiTrackId': 'ts_gb_shoeburyness_ob_3',
        'center': [
          0.45025105,
          51.55701122875883
        ],
        'zoom': 13
      },
      {
        'name': 'Benfleet - Shoeburyness',
        'identifier': 'ts_gb_shoeburyness_ob_4',
        'apiTrackId': 'ts_gb_shoeburyness_ob_4',
        'center': [
          0.6776612,
          51.53796329106907
        ],
        'zoom': 13
      }

    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '44',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '44-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    isVisionEnabled: false,
    slug: 'no_oslo_subway_lambertseterbanen_ib',
    name: 'Lambertseterbanen (T-Bane)',
    title: 'Lambertseterbanen (T-Bane) Inbound',
    center: [10.796363705832164, 59.8789937702559],
    zoom: 13.38,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_lambertseter_ib',
    apiTrackGroups: [
      'no_oslo_subway_lambertseter'
    ],
    apiTrackDirection: 'ib',
    trackName: 'no_oslo_subway_lambertseter_ib',
    trackIdentifier: 'no_oslo_subway_lambertseter_ib',
    hasRailCant: true,
    railCantSections: [
      {
        name: 'Bergkrystallen - Helsfyr',
        identifier: 'no_oslo_subway_lambertseter_ib',
        apiTrackId: 'no_oslo_subway_lambertseter_ib',
        center: [10.796363705832164, 59.8789937702559],
        zoom: 13.38
      }
    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '45',
    countryId: 'norway',
    countryCode: 'no',
    trackId: '45-1',
    isEnabled: process.env.FEATURE_TRACK_SPORVEIEN === 'true',
    isVisionEnabled: false,
    slug: 'no_oslo_subway_lambertseterbanen_ib',
    name: 'Lambertseterbanen (T-Bane)',
    title: 'Lambertseterbanen (T-Bane) Outbound',
    center: [10.796363705832164, 59.8789937702559],
    zoom: 13.38,
    deviceMac: 'None',
    apiTrackId: 'no_oslo_subway_lambertseter_ob',
    apiTrackGroups: [
      'no_oslo_subway_lambertseter'
    ],
    apiTrackDirection: 'ob',
    trackName: 'no_oslo_subway_lambertseter_ob',
    trackIdentifier: 'no_oslo_subway_lambertseter_ob',
    hasRailCant: true,
    railCantSections: [
      {
        'name': 'Helsfyr - Bergkrystallen',
        'identifier': 'no_oslo_subway_lambertseter_ob',
        'apiTrackId': 'no_oslo_subway_lambertseter_ob',
        center: [10.796363705832164, 59.8789937702559],
        zoom: 13.38
      }
    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    id: '46',
    trackId: '46-1',
    countryId: 'united_kingdom',
    countryCode: 'gb',
    slug: 'hs1-ib',
    name: 'HS1 IB',
    title: 'HS1 IB',
    center: [0.022551439834160192, 51.305486771696025],
    // center: [
    //   0.48988149999999997,
    //   51.322620713740186
    // ],
    zoom: 8.43,
    deviceMac: 'None',
    apiTrackId: 'tr_gb_hs1_ib',
    apiTrackGroups: [
      'tg_gb_hs1'
    ],
    apiTrackDirection: 'ib',
    trackName: 'tr_gb_hs1_ib',
    trackIdentifier: 'tr_gb_hs1_ib',
    hasRailCant: true,
    railCantSections: [
      {
        'name': 'Dollands Moor - Harrietsham',
        'identifier': 'ts_gb_hs1_s1_ib',
        'apiTrackId': 'ts_gb_hs1_s1_ib',
        'center': [
          0.7509439889506194,
          51.14757260044743,
        ],
        'zoom': 10
      },
      {
        'name': 'Harrietsham - Ebsfleet International',
        'identifier': 'ts_gb_hs1_s2_ib',
        'apiTrackId': 'ts_gb_hs1_s2_ib',
        'center': [
          0.3586919062170111,
          51.33480632448329
        ],
        'zoom': 10
      },
      {
        'name': 'Ebsfleet International - St Pancras International',
        'identifier': 'ts_gb_hs1_s3_ib',
        'apiTrackId': 'ts_gb_hs1_s3_ib',
        'center': [
          -0.0283624314376573,
          51.48857383959409,
        ],
        'zoom': 10
      }
    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  },
  {
    'id': '47',
    'trackId': '47-1',
    countryId: 'united_kingdom',
    countryCode: 'gb',
    slug: 'hs1-ob',
    name: 'HS1 OB',
    title: 'HS1 OB',
    center: [0.022551439834160192, 51.305486771696025],
    // center: [
    //   0.48988149999999997,
    //   51.322620713740186
    // ],
    zoom: 8.43,
    deviceMac: 'None',
    apiTrackId: 'tr_gb_hs1_ob',
    apiTrackGroups: [
      'tg_gb_hs1'
    ],
    apiTrackDirection: 'ob',
    trackName: 'tr_gb_hs1_ob',
    trackIdentifier: 'tr_gb_hs1_ob',
    hasRailCant: true,
    railCantSections: [
      {
        'name': 'St Pancras International - Ebsfleet International',
        'identifier': 'ts_gb_hs1_s1_ob',
        'apiTrackId': 'ts_gb_hs1_s1_ob',
        'center': [
          -0.0283624314376573,
          51.48857383959409,
        ],
        'zoom': 10
      },
      {
        'name': 'Ebsfleet International - Harrietsham',
        'identifier': 'ts_gb_hs1_s2_ob',
        'apiTrackId': 'ts_gb_hs1_s2_ob',
        'center': [
          0.3586919062170111,
          51.33480632448329
        ],
        'zoom': 10
      },
      {
        'name': 'Harrietsham - Dollands Moor',
        'identifier': 'ts_gb_hs1_s3_ob',
        'apiTrackId': 'ts_gb_hs1_s3_ob',
        'center': [
          0.7509439889506194,
          51.14757260044743,
        ],
        'zoom': 10
      }
    ],
    cantLimits: {
      acceptable: {min: 0, max: 17},
      warning: {min: 17, max: 19},
      majorWarning: {min: 19, max: 23},
      critical: {min: 23, max: Infinity}
    }
  }
];

// Filter out tracks that are disabled
let tracks = [];
for (let i = 0; i < trackDefinitions.length; ++i) {
  const isEnabled = trackDefinitions[i]?.isEnabled ?? true;
  if (isEnabled) {
    tracks.push(trackDefinitions[i]);
  }
}

const getTrackByIdentifier = (trackIdentifier) => {
  for (let i = 0; i < tracks.length; ++i) {
    const track = tracks[i];
    if (track.trackIdentifier === trackIdentifier) {
      return track;
    }
  }
  return null;
};

const getTrackBySlug = (trackSlug) => {
  for (let i = 0; i < tracks.length; ++i) {
    const track = tracks[i];
    if (track.slug === trackSlug) {
      return track;
    }
  }
  return null;
};

const getTrackByApiId = (apiId) => {
  for (let i = 0; i < tracks.length; ++i) {
    const track = tracks[i];
    if (track.apiTrackId === apiId) {
      return track;
    }
  }
  return null;
};

// Returns all apiTrackId values for all defined tracks
const getApiTrackIds = () => {
  let ids = [];
  for (let i = 0; i < tracks.length; ++i) {
    const track = tracks[i];
    if (track?.apiTrackId) {
      if (!ids.find((value) => value === track?.apiTrackId)) {
        ids.push(track?.apiTrackId);
      }
    }
  }
  return ids;
};

// Finds the Rail Cant section that matches the identifier, if it has no section
// it returns the elevationApiTrackId.
// The return value is an object with name, identifier and apiTrackId.
const getRailCantSectionByIdentifier = (trackDefinition, identifier) => {
  if (!trackDefinition) {
    return null;
  }
  if (!trackDefinition?.railCantSections) {
    return {
      name: null,
      identifier: null,
      lowQualityTrackModel: trackDefinition?.lowQualityTrackModel,
      apiTrackId: trackDefinition?.elevationApiTrackId
        ? trackDefinition?.elevationApiTrackId
        : trackDefinition?.apiTrackId
    };
  }
  for (let i = 0; i < trackDefinition.railCantSections.length; ++i) {
    const section = trackDefinition.railCantSections[i];
    if (section.identifier === identifier) {
      return section;
    }
  }
  return null;
};

// Finds the Rail Cant section that matches the identifier
const getUniqueRailCantSection = (identifier) => {
  for (let i = 0; i < tracks.length; ++i) {
    const trackDefinition = tracks[i];
    if (!trackDefinition?.railCantSections) {
      if (trackDefinition.elevationApiTrackId === identifier) {
        // There are no individual sections so use entire track as section data
        return {
          name: trackDefinition.name,
          identifier: trackDefinition.identifier,
          apiTrackId: trackDefinition.elevationApiTrackId,
          center: trackDefinition.center,
          zoom: trackDefinition.zoom,
          isSection: false
        };
      }
      continue;
    }
    for (let j = 0; j < trackDefinition.railCantSections.length; ++j) {
      const section = trackDefinition.railCantSections[j];
      if (section.identifier === identifier) {
        return section;
      }
    }
  }
  return null;
};

// Finds the Rail Cant track that matches the section identifier
const getTrackByRailCanSection = (identifier) => {
  for (let i = 0; i < tracks.length; ++i) {
    const trackDefinition = tracks[i];
    if (!trackDefinition?.railCantSections) {
      if (trackDefinition.elevationApiTrackId === identifier) {
        return trackDefinition;
      }
      continue;
    }
    for (let j = 0; j < trackDefinition.railCantSections.length; ++j) {
      const section = trackDefinition.railCantSections[j];
      if (section.identifier === identifier) {
        return trackDefinition;
      }
    }
  }
  return null;
};

// Finds the Rail Cant section that matches the group and section
// NOTE: The section is required as the group represents both inbound and outbound track
const getTrackByGroupIdentifier = (groupIdentifier, sectionIdentifier) => {
  for (let i = 0; i < tracks.length; ++i) {
    const trackDefinition = tracks[i];
    if (
      trackDefinition?.apiTrackGroups &&
      trackDefinition.apiTrackGroups.includes(groupIdentifier)
    ) {
      if (trackDefinition?.railCantSections) {
        // Look for it by using sections
        for (let j = 0; j < trackDefinition.railCantSections.length; ++j) {
          const section = trackDefinition.railCantSections[j];
          if (section.identifier === sectionIdentifier) {
            return trackDefinition;
          }
        }
      } else {
        // Otherwise use the id of the track
        if (
          trackDefinition.elevationApiTrackId === sectionIdentifier ||
          trackDefinition.apiTrackId === sectionIdentifier
        ) {
          return trackDefinition;
        }
      }
    } else {
      // Look for it by using sections
      if (trackDefinition?.railCantSections) {
        for (let j = 0; j < trackDefinition.railCantSections.length; ++j) {
          const section = trackDefinition.railCantSections[j];
          if (section.identifier === sectionIdentifier) {
            return trackDefinition;
          }
        }
      } else {
        // Several of the original tracks does not have a group set (those in norway)
        if (
          trackDefinition.elevationApiTrackId === sectionIdentifier ||
          trackDefinition.apiTrackId === sectionIdentifier
        ) {
          return trackDefinition;
        }
      }
    }
  }
  return null;
};

export {
  getTrackByIdentifier,
  getTrackBySlug,
  getTrackByApiId,
  getApiTrackIds,
  getRailCantSectionByIdentifier,
  getUniqueRailCantSection,
  getTrackByRailCanSection,
  getTrackByGroupIdentifier
};

export default tracks;
